<template>
  <div
    class="py-3"
    >
    <v-dialog
      v-model="dialog"
      fullscreen
      content-class="dialog-bg"
      >
      <v-img
        v-if="selected"
        contain
        height="100%"
        width="100%"
        :src="url + selected.imageUrl"
        >
        <v-btn
          fab
          small
          color="white"
          @click="dialog = false"
          style="position: absolute; right: 12px; top: 12px"
          >
          <v-icon
            color="black"
            >mdi-close</v-icon>
        </v-btn>
        <v-btn
          style="position: absolute; right: 12px; top: 50%;"
          fab
          small
          @click="next"
          color="white"
          >
          <v-icon
            color="black"
            >mdi-chevron-right</v-icon>
        </v-btn>
        <v-btn
          style="position: absolute; left: 12px; top: 50%;"
          fab
          small
          color="white"
          @click="prev"
          >
          <v-icon
            color="black"
            >mdi-chevron-left</v-icon>
        </v-btn>

      </v-img>
    </v-dialog>

    <div
      v-if="banners.length"
      style="gap: 8px"
      class="d-flex flex-wrap"
      >
      <v-card
        flat
        color="primary"
        class="rounded-lg mb-3"
        @click="openUpload"
        height="100"
        width="100"
        >
        <v-card-text
          class="white--text text-uppercase font-weight-medium d-flex fill-height align-center justify-center"
          >
          <v-icon x-large color="white">mdi-plus-circle</v-icon>
        </v-card-text>
      </v-card>

      <template
        v-for="banner in banners"
        >
        <v-card
          height="100"
          width="100"
          flat
          class="rounded-lg text-center mb-2"
          style="position: relative"
          @click="openDialog(banner)"
          >
          <v-img
            width="100"
            height="100"
            :src="url + banner.imageUrl"
            class="d-flex align-center justify-center text-center rounded-lg"
            >
          </v-img>

          <v-btn
            fab
            x-small
            elevation="3"
            color="white"
            @click.stop="deleteBanner(banner.id)"
            style="position: absolute; bottom: -12px; left: calc(50% - 16px)"
            >
            <v-icon
              color="error"
              >
              mdi-delete
            </v-icon>
          </v-btn>
        </v-card>
      </template>
    </div>

    <div
      v-else
      >
      <v-card
        outlined
        class="rounded-lg justify-center align-center d-flex"
        height="112"
        @click="openUpload"
        >
        <v-card-text
          class="text-center"
          >
          <div>
            ¡Parece que aún no tienes ningún banner cargado!
          </div>

          <div
            class="info--text font-weight-medium body-1 mt-2"
            >
            Haz click aquí para cargar uno.
          </div>
        </v-card-text>
      </v-card>
    </div>

    <div>

      <input
        class="d-none"
        ref="picture"
        type="file"
        accept="image/*"
        @change="uploadPicture"
        ></input>
    </div>

    <v-alert
      type="info"
      outlined
      border="left"
      dense
      class="mt-6 mb-0 rounded-lg"
      >
      Las dimensiones del banner deben ser de {{ resolution == 'desktop' ? '950px x 300px' : '300px x 150px' }}.
    </v-alert>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { EditFair } from '@/graphql/mutations/fairs/fairs'
import { DeleteBanner } from '@/graphql/mutations/banner'
import { Banners } from '@/graphql/queries/fairs/fairs'

export default {
  data: () => ({
    banners: [],
    selected: null,
    loading: true,
    dialog: false,
    attributes: {
      bannerType: '',
      imageAttributes: {

      }
    }
  }),

  props: {
    resolution: {
      type: String,
      required: true
    },
  },

  created () {
    this.fetchBanners()
    
    this.attributes.bannerType = this.resolution
  },

  computed: {
    ...mapGetters(['url'])
  },

  methods: {
    fetchBanners () {
      this.$apollo.query({
        query: Banners,
        variables: {
          id: this.$route.params.id,
          resolution: this.resolution
        },
        fetchPolicy: 'no-cache'
      }).then( res => {
        this.loading = false

        this.banners = res.data.fairBanners
      })
    },

    openUpload () {
      this.$refs.picture.click()
    },

    deleteBanner (id) {
      this.$apollo.mutate({
        mutation: DeleteBanner,
        variables: {
          input: {
            id: id
          }
        }
      }).then ( res => {
        this.fetchBanners()
      })
    },

    uploadPicture (e) {
      var file = e.target.files[0]

      var imageAttributes = {
        name: file.name,
        size: file.size,
        image: file,
      }

      this.attributes.imageAttributes = imageAttributes

      this.$apollo.mutate({
        mutation: EditFair,
        variables: {
          input: {
            attributes: {
              bannersAttributes: [
                this.attributes
              ]
            },
            id: this.$route.params.id,
          }
        }
      }).then ( res => {
        this.fetchBanners()
      })
    },

    openDialog (banner) {
      this.dialog = true
      this.selected = banner
    },

    next () {
      if (this.selected) {
        const curr = this.banners.indexOf(this.selected)
        const next = (curr + 1 >= this.banners.length) ? 0 : curr + 1
        this.selected = this.banners[next]
      }
    },

    prev () {
      if (this.selected) {
        const curr = this.banners.indexOf(this.selected)
        const next = (curr - 1 < 0) ? this.banners.length - 1 : curr - 1
        this.selected = this.banners[next]
      }
    }
  }
}
</script>
