import gql from 'graphql-tag'

export const CreateFair = gql`
  mutation createFair($input: CreateFairInput!) {
    createFair(input: $input) {
      fair {
        id
      }
    }
  }
`

export const EditFair = gql`
  mutation editFair($input: EditFairInput!) {
    editFair(input: $input) {
      success
    }
  }
`
