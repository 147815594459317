<template>
  <v-card
    max-height="calc(100vh - 48px - 32px)"
    class="rounded-lg"
    >
    <v-card-text
      class="black--text"
      >
      <div
        class="text-center"
        >
        <v-icon
          x-large
          color="primary"
          >
          mdi-image-outline
        </v-icon>
      </div>

      <div
        class="text-h6 font-weight-bold text-center mb-3 primary--text"
        >
        Banners
      </div>

      <v-tabs
        v-model="tab"
        background-color="transparent"
        color="primary"
        grow
        >
        <v-tab
          >
          PC
        </v-tab>

        <v-tab
          >
          Celular
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item
          >
          <list
            resolution="desktop"
            ></list>
        </v-tab-item>

        <v-tab-item
          >
          <list
            resolution="mobile"
            ></list>
        </v-tab-item>
      </v-tabs-items>
    </v-card-text>
  </v-card>
</template>

<script>
import List from './banners/List'

export default {
  data: () => ({
    tab: 0
  }),

  components: {
    List
  },
}
</script>
